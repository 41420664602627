
.shopping-list-item:not(:last-child) {
  border-bottom: 1px solid #dbdbdb;
}
.shopping-list-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.shopping-list-item:not(a) {
  color: #4a4a4a;
}
.shopping-list-item {
  background-color: white;
}
.shopping-list-item .show {
  display: flex;
}

.shopping-list-item .show .tickbox {
  display: inline-block;
  padding: 0.7rem 0.3rem;
  margin-right: 5px;
}

.shopping-list-item .show .tickbox div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
}

.shopping-list-item .show .editable {
  padding-top: 0.45rem;
  margin-bottom: 0.25rem;
}

.shopping-list-item .show .extra {
  margin-top: 0.25rem;
  padding-bottom: 0.35rem;
  color: #768390;
}

.shopping-list-item .edit {
  padding: 0.35rem 15px;
}

.bottom-toolbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  background: rgb(250, 250, 250);
  border-top: 1px solid rgb(238, 238, 238);
  z-index: 1000;
  padding: 5px;
}

@media screen and (max-width: 500px) {
  .bottom-toolbar {
    height: 75px;
  }
}
