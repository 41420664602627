
.remove-image {
  margin-right: 10px;
}

.remove-image svg {
  float: right;
  display: none;
  margin: 4px;
}

.remove-image:hover svg {
  display: block;
}
