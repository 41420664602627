
.app-error {
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
}

.has-background-danger {
  background-color: #ff3860 !important;
}
