.seasonal-produce-section {
  margin-top: 15px;
}

.seasonal-produce-item {
  /* display: grid;
  grid-template-columns: [emo] 25px [line2] 50% [line3] 50% [end]; */
  background-color: white;
  padding: 8px 5px;
  /* height: 70px; */
}

.seasonal-produce-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.seasonal-produce-item:not(:last-child) {
  border-bottom: 1px solid #dbdbdb;
}
