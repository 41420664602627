
.recipe-autocomplete-suggestions {
  text-align: left; cursor: default; border: 1px solid #ccc; border-top: 0; background: #fff; box-shadow: -1px 1px 3px rgba(0,0,0,.1);

  /* core styles should not be changed */
  position: absolute; display: none; z-index: 9999; max-height: 254px; overflow: hidden; overflow-y: auto; box-sizing: border-box;
}
.recipe-autocomplete-suggestions-wrapper {
  display: flex;
}
.recipe-autocomplete-suggestions-wrapper img {
  padding-right: 5px;
  width: 70px;
}
.recipe-autocomplete-suggestions-wrapper .auto-complete-title {
  white-space: normal;
}
.recipe-autocomplete-suggestions .autocomplete-suggestion { padding: 0; margin: 0; line-height: 26px; overflow: hidden; text-overflow: ellipsis; font-size: 1.02em; color: #333; }
.recipe-autocomplete-suggestions .autocomplete-suggestion.selected { background: #f0f0f0; }
