
.serves {
  margin-bottom: 1.5rem;
}

.ingredients {
  margin-bottom: 1.5rem;
}

.ingredients .hide {
  display: none;
}

.subtitle.text-section-header {
  margin-top: 1rem;
  margin-bottom: 0.6rem;
}

.instruction {
  margin-bottom: 0.3rem;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}
.tag {
  padding: 3px 7px;
  margin: 3px;
  border-radius: 4px;
  background-color: #f1f1f1;
  white-space: nowrap;
}

.clickable {
  cursor: pointer;
}

.clickable-text {
  text-decoration: underline;
  text-decoration-color: #ebebeb;
  text-decoration-style: dashed;
  text-underline-position: under;
  cursor: pointer;
}

.aside {
  border-color: #dbdbdb;
  border-radius: 4px;
  border-style: solid;
  border-width: 0 0 0 4px;
  color: #4a4a4a;
  padding: 1.25em 1.5em;
  background-color: #f5f5f5;
}

.ingredient-group {
  padding-top: 15px;
}
